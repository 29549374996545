.page-title {
  padding: 0px 10px 10px;
}
.misc-content {
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
