/*
  Site variables
*/

$base-font-family: 'Roboto', sans-serif;

$body-font-family: 'Roboto', sans-serif;

$code-font-family: "Source Code Pro", monospace;

$brand-color: black;

$container-width: 1100px;

$tablet-width: 600px;

$phone-width: 480px;

$header-thickness: 56px;

@import "_sass/-sections-dir";
